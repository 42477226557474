
import { defineComponent, nextTick, onMounted, ref, watch } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import Rating from "../Rating.vue";
import axios from "axios";
import { useRoute } from "vue-router";
const video = ref();
const canvas = ref();
const captures = ref({});
const isCameraOpen = ref(false);
const listData = ref();
const limit = ref(6);
const page = ref(0);
const orderBy = ref(1);
const categories = ref([]);
const totalPage = ref(0);
const listCertificate = ref();
const filterForm = ref({
  recommend: "",
  category: "",
  status: "",
  sortBy: "3",
  search: "",
  hashtag: "",
});
export default defineComponent({
  name: "voluntary",
  components: {
    Rating,
  },
  data() {
    return {
      categoryList: [],
      imageUrl: process.env.VUE_APP_IMG_URL,
    };
  },
  setup() {
    /* eslint-disable */
    const route = useRoute();
    onMounted(() => {
      if (route.query.recommend) {
        filterForm.value.recommend = route.query.recommend + "";
      }
      if (route.query.hashtag) {
        filterForm.value.hashtag = route.query.hashtag + "";
      }
      getDataList();
    });
    watch(
      () => route.path,
      () => {
        nextTick(() => {
          if (route.query.recommend) {
            filterForm.value.recommend = route.query.recommend + "";
          }
          if (route.query.hashtag) {
            filterForm.value.hashtag = route.query.hashtag + "";
          }
          getDataList();
        });
      }
    );
    const getDataList = () => {
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/getCourse?sizeContents=" +
            limit.value +
            "&page=" +
            page.value +
            "&orderBy=" +
            orderBy.value +
            "&sort=" +
            filterForm.value.sortBy +
            "&category=" +
            filterForm.value.category +
            "&registerStatus=" +
            filterForm.value.status +
            "&keyWord=" +
            filterForm.value.search +
            "&recommend=" +
            filterForm.value.recommend +
            "&hashtag=" +
            filterForm.value.hashtag +
            "&flgGeneral=1",
          {
            headers: {
              token: localStorage.getItem("id_token")
              ? localStorage.getItem("id_token")
              : localStorage.getItem("tokenGuest"),
            },
          }
        )
        .then((response) => {
          // console.log(response);
          listData.value = response.data.data.content;
          listData.value["totalElements"] = response.data.data.totalElements;
          totalPage.value = response.data.data.totalPages;
        })
        .catch((error) => {
          // console.log(error);
        });
    };
    const changePage = (pageEvent) => {
      // console.log(pageEvent);
      page.value = pageEvent - 1;
      getDataList();
    };
    const backPage = () => {
      page.value = page.value - 1;
      getDataList();
    };
    const nextPage = () => {
      page.value = page.value + 1;
      getDataList();
    };
    const searchData = () => {
      getDataList();
    };
    const getCertificateId = async (id) => {
      const certificate = await axios.get(
        process.env.VUE_APP_API_URL +
          "/getCourseRegister?courseId=" +
          id +
          "&userId=" +
          localStorage.getItem("u_id"),
        {
          headers: {
            token: localStorage.getItem("id_token")
          },
        }
      );
      // console.log(certificate.data.data.content[0]);
      listCertificate.value = certificate.data.data.content[0];
      downloadCertificate();
    };
    const downloadCertificate = async () => {
      const director = await axios.get(
        process.env.VUE_APP_API_URL + "/getDirector",
        {
          headers: {
            token: localStorage.getItem("id_token"),
          },
        }
      );
      let date: String =
        new Date(listCertificate.value.certApproveDate).toLocaleDateString(
          "th-TH",
          {
            year: "numeric",
            month: "long",
            day: "numeric",
          }
        ) + "";
      const url =
        process.env.VUE_APP_REPORT_URL +
        "&title=" +
        listCertificate.value.authorProfile.prefix +
        "&course=" +
        listCertificate.value.courseProfile.title +
        "&firstname=" +
        listCertificate.value.authorProfile.firstName +
        "&lastname=" +
        listCertificate.value.authorProfile.lastName +
        "&date=" +
        arabic_to_thai(date) +
        "&license=" +
        process.env.VUE_APP_IMG_URL +
        director.data.data.signature +
        "&licenseName=" +
        director.data.data.firstName +
        " " +
        director.data.data.lastName +
        "&position=" +
        director.data.data.position;
      window.open(url.replace("/fileName", "/cert_dede2.pdf"), "_blank");
    };
    const arabic_to_thai = (text) => {
    let data = text.replaceAll("1", "๑").replaceAll("2", "๒").replaceAll("3", "๓").replaceAll("4", "๔").replaceAll("5", "๕").replaceAll("6", "๖").replaceAll("7", "๗").replaceAll("8", "๘").replaceAll("9", "๙").replaceAll("0", "๐")
    return data
}
    return {
      video,
      canvas,
      captures,
      isCameraOpen,
      getDataList,
      changePage,
      backPage,
      nextPage,
      listData,
      limit,
      page,
      orderBy,
      categories,
      totalPage,
      filterForm,
      searchData,
      getCertificateId,
      downloadCertificate,
      listCertificate,
      arabic_to_thai
    };
  },
  methods: {
    formatDate(date) {
      const event = new Date(date);
      let dateString =
        event.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "long",
          day: "numeric",
        }) + "";
      return dateString;
    },
    removeStyle(html) {
      const regex = /(style=".+?")/gm;
      const str = html;
      const subst = ``;
      const result = str.replace(regex, subst);
      return result;
    },
    setAltImg(event) {
      event.target.src = "media/image-app/no-image.svg";
    },
    /* eslint-disable */
    renderCategory(list) {
      let data: any = [];
      for (const looplist of list) {
        data.push(looplist[0].data);
      }
      if (data.length > 0) {
        return data.join(",");
      } else {
        return "-";
      }
    },
    renderStatus(status) {
      if (status == 1 || status == 2) {
        return "<span style='color:#9999D3'>เรียนต่อ</span>";
      } else if (status == 3) {
        return "<span style='color:#31B67C'>เรียนจบ</span>";
      } else if (status == 4) {
        return "<span style='color:#FF7786'>ไม่ผ่านหลักสูตร</span>";
      } else if (status == 5) {
        return "<span style='color:#F2A17D'>หมดเวลาเรียน</span>";
      } else {
        return "-";
      }
    },
  },
  async mounted() {
    const category = await axios.get(
      process.env.VUE_APP_API_URL + "/category?categoryType=course",
      { headers: {
        token: localStorage.getItem("id_token")
              ? localStorage.getItem("id_token")
              : localStorage.getItem("tokenGuest"),
      } }
    );
    // console.log(category);
    this.categoryList = category.data.data;
  },
});
