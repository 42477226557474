<template>
  <!--begin::Dashboard-->
  <div class="p-5 container">
    <div class="mb-5 row">
      <div class="col-12 col-lg mb-2">
        <label for="" class="text-category" style="color:#9999D3"
          >ผู้ใช้งาน</label
        >
        <el-select
          size="large"
          class="w-100"
          filterable
          v-model="filterForm.recommend"
          @change="searchData"
          placeholder="ผู้ใช้งาน"
          autocomplete="one-time-code"
        >
          <el-option value="" label="ทั้งหมด"></el-option>
          <el-option value="1" label="ประชาชนทั่วไป"></el-option>
          <el-option value="2" label="นักเรียนนักศึกษา"></el-option>
          <el-option value="3" label="คณะทำงานด้านเทคนิค"></el-option>
          <el-option
            value="4"
            label="เจ้าหน้าที่ปกครองส่วนท้องถิ่น"
          ></el-option>
          <el-option value="5" label="วิศวกร"></el-option>
          <el-option value="6" label="ผู้พัฒนาโครงการ"></el-option>
          <el-option value="7" label="ผู้บริหาร/เจ้าของกิจการ"></el-option>
        </el-select>
      </div>
      <div class="col-12 col-lg mb-2">
        <label for="" class="text-category" style="color:#9999D3"
          >หมวดหมู่</label
        >
        <el-select
          size="large"
          class="w-100"
          filterable
          v-model="filterForm.category"
          @change="searchData"
          placeholder="หมวดหมู่"
          autocomplete="one-time-code"
        >
          <el-option value="" label="ทั้งหมด"></el-option>
          <el-option
            v-for="ct in categoryList"
            :key="ct.categoryCode"
            :value="ct.categoryCode"
            :label="ct.categoryName[0].data"
          />
        </el-select>
      </div>
      <div class="col-12 col-lg mb-2">
        <label for="" class="text-category" style="color:#9999D3">สถานะ</label>
        <el-select
          size="large"
          class="w-100"
          filterable
          v-model="filterForm.status"
          @change="searchData"
          placeholder="สถานะ"
          autocomplete="one-time-code"
        >
          <el-option value="" label="ทั้งหมด"></el-option>
          <el-option value="-1" label="ยังไม่ได้ลงทะเบียน"></el-option>
          <el-option value="1,2" label="เรียนต่อ"></el-option>
          <!-- <el-option value="1" label="ลงทะเบียนแต่ยังไม่ได้เรียน"></el-option>
          <el-option value="2" label="เรียนต่อ"></el-option> -->
          <el-option value="3" label="จบหลักสูตร"></el-option>
          <!-- <el-option value="4" label="ไม่ผ่านหลักสูตร"></el-option> -->
          <!-- <el-option value="5" label="หมดเวลาเรียน"></el-option> -->
        </el-select>
      </div>
      <div class="col-12 col-lg mb-2">
        <label for="" class="text-category" style="color:#9999D3"
          >เรียงตาม</label
        >
        <el-select
          size="large"
          class="w-100"
          filterable
          v-model="filterForm.sortBy"
          @change="searchData"
          placeholder="เรียงตาม"
          autocomplete="one-time-code"
        >
          <el-option value="" label="ทั้งหมด"></el-option>
          <el-option value="2" label="เรียงตามตัวอักษร"></el-option>
          <el-option value="3" label="ใหม่ล่าสุด"></el-option>
          <el-option value="4" label="ยอดนิยม"></el-option>
          <el-option value="1" label="แนะนำ"></el-option>
        </el-select>
      </div>
      <div class="col-12 col-lg mb-2">
        <label for="" class="text-category" style="color:#9999D3">ค้นหา</label>
        <input
          class="form-control"
          type="text"
          v-model="filterForm.search"
          @change="searchData"
          autocomplete="one-time-code"
          style="height:40px"
          placeholder="ค้นหา"
        />
      </div>
    </div>
    <div class="row mb-5" v-if="listData && listData.length > 0">
      <div
        class="col-12 col-lg-4 mb-5"
        style="padding: 10px;"
        v-for="(item, index) in listData"
        :key="index"
      >
        <router-link :to="'/course/voluntary/' + item.id">
          <div
            class="card box-item"
            style="border-radius: 10px;position: relative;border: 1px solid #E9E9EF;min-height: 300px"
          >
            <div style="position: relative;">
              <img
                :src="imageUrl + item.thumbnailsPath"
                @error="setAltImg"
                alt=""
                class="w-100"
                style="border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            object-fit: cover;"
              />
              <div
                style="position: absolute;left: 10px;bottom: 10px;display: flex;flex-direction: row;
                    flex-wrap: nowrap;
                    overflow-x: hidden;"
              >
                <div
                  style="margin-right:5px"
                  v-for="(itemHas, index) in item.hashtag"
                  :key="index"
                >
                  <span class="badge badge-danger">{{ itemHas }}</span>
                </div>
              </div>
            </div>

            <div class="card-body responsive-body" style="min-height: 153px">
              <div
                class="mb-5 limit-text text-title"
                style="font-weight: 400;
                color: #323563;"
              >
                {{ item.title }}
              </div>
              <Rating :value="item.rate ? item.rate : 0" :max="5" />
              <!-- <div class="text-category" style="color:#9999D3">
                  หมวดหมู่ :
                  <span v-html="renderCategory(item.categoryProfile)"></span>
                </div> -->
              <!-- <div
                class="mb-5 limit-text text-info"
                style="font-weight: 400;
                color: #9F9F9F!important;"
                v-html="removeStyle(item.description)"
              ></div> -->
            </div>
            <div class="card-footer">
              <div
                class="d-flex align-items-center flex-column mt-3 w-100"
                v-if="item.register == true && item.progressUpdate != 100"
              >
                <div
                  class="d-flex justify-content-between fw-bold fs-6 text-dark w-100 mt-auto mb-2"
                >
                  <span style="font-weight: 600;"
                    >สถานะ :
                    <span v-html="renderStatus(item.statusRegister)"></span
                  ></span>
                  <span>{{ item.progressUpdate }}%</span>
                </div>
                <div
                  class="h-8px mx-3 w-100 rounded"
                  style="background:#E1F2FF"
                >
                  <div
                    class="bg-progressbar rounded h-8px"
                    data-bs-toggle="tooltip"
                    :title="item.progressUpdate + '%'"
                    role="progressbar"
                    :style="'width: ' + item.progressUpdate + '%'"
                    aria-valuenow="50"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
              <div v-else style="display: flex;justify-content: center;">
                <a
                  href="#"
                  v-if="item.register == true && item.progressUpdate == 100"
                  class="btn text-btn"
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_cert"
                  @click="getCertificateId(item.id)"
                  style="border-radius: 21px;background: #31B67C;color: #FFF;min-width: 235px;"
                  >ดาวน์โหลดใบรับรอง</a
                >
                <router-link
                  v-else
                  :to="'/course/voluntary/' + item.id"
                  type="button"
                  class="btn btn-register2 text-btn"
                >
                  ลงทะเบียน
                </router-link>
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
    <div class=" row mb-5" v-else>
      <img src="media/image-app/no-data.svg" alt="" style="height:300px" />
    </div>
    <div
      v-if="listData && listData.length > 0"
      style="display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;
                flex-direction: row;"
    >
      <b class="text-category" style="color:#9999D3"
        >จำนวน {{ listData && listData.totalElements }} รายการ</b
      >
      <ul class="pagination">
        <li :class="'page-item previous ' + (page == 0 ? 'disabled' : '')">
          <button type="button" @click="backPage()" class="page-link">
            <i class="previous"></i>
          </button>
        </li>
        <div v-for="(item, index) in totalPage" :key="index">
          <li :class="'page-item ' + (index == page ? 'active' : '')">
            <button type="button" @click="changePage(item)" class="page-link">
              {{ item }}
            </button>
          </li>
        </div>
        <li
          :class="'page-item next ' + (page + 1 == totalPage ? 'disabled' : '')"
        >
          <button type="button" @click="nextPage()" class="page-link">
            <i class="next"></i>
          </button>
        </li>
      </ul>
    </div>
  </div>
  <!-- <div class="modal fade" tabindex="-1" id="kt_modal_cert">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-body">
          <div style="text-align:center;" v-if="listCertificate">
            <div>
              <img src="media/image-app/image 10.svg" alt="" class="w-50" />
            </div>
            <div>
              <p class="text-title my-10">ขอมอบใบรับรองนี้ให้แก่</p>
              <p class="text-title my-20">
                {{ listCertificate.authorProfile.prefix }}
                {{ listCertificate.authorProfile.firstName }}
                {{ listCertificate.authorProfile.lastName }}
              </p>
              <p class="text-title my-10">
                เพื่อแสดงว่าได้ผ่านการฝึกอบรมออนไลน์หลักสูตร
              </p>
              <p class="text-title my-10">
                {{ listCertificate.courseProfile.title }}
              </p>
              <p class="text-title my-10">
                ให้ไว้ ณ วันที่
                {{ formatDate(listCertificate.certApproveDate) }}
              </p>
              <p class="text-title" style="margin-top:15vh">
                (นางมัณลิกา สมพรานนท์)
              </p>
              <p class="text-title my-10">
                ผู้อำนวยการกองพัฒนาทรัพยากรบุคคลด้านพลังงาน ปฏิบัติราชการแทน
              </p>
              <p class="text-title my-10">
                อธิบดีกรมพัฒนาพลังงานทดแทนและอนุรักษ์พลังงาน
              </p>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-light" data-bs-dismiss="modal">
            ปิด
          </button>
          <button
            type="button"
            class="btn "
            style="border-radius: 21px;background: #31B67C;color: #FFF;"
            @click="downloadCertificate"
          >
            ดาวน์โหลดใบรับรอง
          </button>
        </div>
      </div>
    </div>
  </div> -->
</template>
<script lang="ts">
import { defineComponent, nextTick, onMounted, ref, watch } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import Rating from "../Rating.vue";
import axios from "axios";
import { useRoute } from "vue-router";
const video = ref();
const canvas = ref();
const captures = ref({});
const isCameraOpen = ref(false);
const listData = ref();
const limit = ref(6);
const page = ref(0);
const orderBy = ref(1);
const categories = ref([]);
const totalPage = ref(0);
const listCertificate = ref();
const filterForm = ref({
  recommend: "",
  category: "",
  status: "",
  sortBy: "3",
  search: "",
  hashtag: "",
});
export default defineComponent({
  name: "voluntary",
  components: {
    Rating,
  },
  data() {
    return {
      categoryList: [],
      imageUrl: process.env.VUE_APP_IMG_URL,
    };
  },
  setup() {
    /* eslint-disable */
    const route = useRoute();
    onMounted(() => {
      if (route.query.recommend) {
        filterForm.value.recommend = route.query.recommend + "";
      }
      if (route.query.hashtag) {
        filterForm.value.hashtag = route.query.hashtag + "";
      }
      getDataList();
    });
    watch(
      () => route.path,
      () => {
        nextTick(() => {
          if (route.query.recommend) {
            filterForm.value.recommend = route.query.recommend + "";
          }
          if (route.query.hashtag) {
            filterForm.value.hashtag = route.query.hashtag + "";
          }
          getDataList();
        });
      }
    );
    const getDataList = () => {
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/getCourse?sizeContents=" +
            limit.value +
            "&page=" +
            page.value +
            "&orderBy=" +
            orderBy.value +
            "&sort=" +
            filterForm.value.sortBy +
            "&category=" +
            filterForm.value.category +
            "&registerStatus=" +
            filterForm.value.status +
            "&keyWord=" +
            filterForm.value.search +
            "&recommend=" +
            filterForm.value.recommend +
            "&hashtag=" +
            filterForm.value.hashtag +
            "&flgGeneral=1",
          {
            headers: {
              token: localStorage.getItem("id_token")
              ? localStorage.getItem("id_token")
              : localStorage.getItem("tokenGuest"),
            },
          }
        )
        .then((response) => {
          // console.log(response);
          listData.value = response.data.data.content;
          listData.value["totalElements"] = response.data.data.totalElements;
          totalPage.value = response.data.data.totalPages;
        })
        .catch((error) => {
          // console.log(error);
        });
    };
    const changePage = (pageEvent) => {
      // console.log(pageEvent);
      page.value = pageEvent - 1;
      getDataList();
    };
    const backPage = () => {
      page.value = page.value - 1;
      getDataList();
    };
    const nextPage = () => {
      page.value = page.value + 1;
      getDataList();
    };
    const searchData = () => {
      getDataList();
    };
    const getCertificateId = async (id) => {
      const certificate = await axios.get(
        process.env.VUE_APP_API_URL +
          "/getCourseRegister?courseId=" +
          id +
          "&userId=" +
          localStorage.getItem("u_id"),
        {
          headers: {
            token: localStorage.getItem("id_token")
          },
        }
      );
      // console.log(certificate.data.data.content[0]);
      listCertificate.value = certificate.data.data.content[0];
      downloadCertificate();
    };
    const downloadCertificate = async () => {
      const director = await axios.get(
        process.env.VUE_APP_API_URL + "/getDirector",
        {
          headers: {
            token: localStorage.getItem("id_token"),
          },
        }
      );
      let date: String =
        new Date(listCertificate.value.certApproveDate).toLocaleDateString(
          "th-TH",
          {
            year: "numeric",
            month: "long",
            day: "numeric",
          }
        ) + "";
      const url =
        process.env.VUE_APP_REPORT_URL +
        "&title=" +
        listCertificate.value.authorProfile.prefix +
        "&course=" +
        listCertificate.value.courseProfile.title +
        "&firstname=" +
        listCertificate.value.authorProfile.firstName +
        "&lastname=" +
        listCertificate.value.authorProfile.lastName +
        "&date=" +
        arabic_to_thai(date) +
        "&license=" +
        process.env.VUE_APP_IMG_URL +
        director.data.data.signature +
        "&licenseName=" +
        director.data.data.firstName +
        " " +
        director.data.data.lastName +
        "&position=" +
        director.data.data.position;
      window.open(url.replace("/fileName", "/cert_dede2.pdf"), "_blank");
    };
    const arabic_to_thai = (text) => {
    let data = text.replaceAll("1", "๑").replaceAll("2", "๒").replaceAll("3", "๓").replaceAll("4", "๔").replaceAll("5", "๕").replaceAll("6", "๖").replaceAll("7", "๗").replaceAll("8", "๘").replaceAll("9", "๙").replaceAll("0", "๐")
    return data
}
    return {
      video,
      canvas,
      captures,
      isCameraOpen,
      getDataList,
      changePage,
      backPage,
      nextPage,
      listData,
      limit,
      page,
      orderBy,
      categories,
      totalPage,
      filterForm,
      searchData,
      getCertificateId,
      downloadCertificate,
      listCertificate,
      arabic_to_thai
    };
  },
  methods: {
    formatDate(date) {
      const event = new Date(date);
      let dateString =
        event.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "long",
          day: "numeric",
        }) + "";
      return dateString;
    },
    removeStyle(html) {
      const regex = /(style=".+?")/gm;
      const str = html;
      const subst = ``;
      const result = str.replace(regex, subst);
      return result;
    },
    setAltImg(event) {
      event.target.src = "media/image-app/no-image.svg";
    },
    /* eslint-disable */
    renderCategory(list) {
      let data: any = [];
      for (const looplist of list) {
        data.push(looplist[0].data);
      }
      if (data.length > 0) {
        return data.join(",");
      } else {
        return "-";
      }
    },
    renderStatus(status) {
      if (status == 1 || status == 2) {
        return "<span style='color:#9999D3'>เรียนต่อ</span>";
      } else if (status == 3) {
        return "<span style='color:#31B67C'>เรียนจบ</span>";
      } else if (status == 4) {
        return "<span style='color:#FF7786'>ไม่ผ่านหลักสูตร</span>";
      } else if (status == 5) {
        return "<span style='color:#F2A17D'>หมดเวลาเรียน</span>";
      } else {
        return "-";
      }
    },
  },
  async mounted() {
    const category = await axios.get(
      process.env.VUE_APP_API_URL + "/category?categoryType=course",
      { headers: {
        token: localStorage.getItem("id_token")
              ? localStorage.getItem("id_token")
              : localStorage.getItem("tokenGuest"),
      } }
    );
    // console.log(category);
    this.categoryList = category.data.data;
  },
});
</script>
<style>
.el-input__inner {
  background: var(--e-9-e-9-ef, #e9e9ef);
  border: 0px;
  border-radius: 10px;
}
</style>
