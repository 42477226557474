<template>
  <div class="rating">
    <span
      v-if="form == false"
      class="text-category"
      style="color:#9999D3;margin-right: 5px;"
    >
      {{ fixScore(value) }}
    </span>
    <div v-if="form == false">
      <span
        v-for="star in stars"
        :key="star"
        :class="
          'star ' + (value >= star || value >= star - 0.5 ? 'filled' : '')
        "
      >
        <i
          v-if="value >= star - 0.5 && star > value"
          class="fa fa-star-half-alt fs-2"
        ></i>
        <i v-else class="fa fa-star fs-2"></i>
      </span>
    </div>
    <div v-else>
      <span
        v-for="star in stars"
        :key="star"
        @click="selectRating(star)"
        :class="
          'star ' + (value >= star || value >= star - 0.5 ? 'filled' : '')
        "
      >
        <i
          v-if="value >= star - 0.5 && star > value"
          class="fa fa-star-half-alt fs-2"
        ></i>
        <i v-else class="fa fa-star fs-2"></i>
      </span>
    </div>
  </div>
  <!-- @click="selectRating(star)" -->
</template>
<script>
export default {
  name: "Rating",
  props: {
    form: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Number,
      required: true,
      
    },
    index: {
      type: Number,
      required: false,
      default: 0,
    },
    max: {
      type: Number,
      default: 5,
    },
  },
  data() {
    // return {
    //   // eslint-disable-next-line vue/no-dupe-keys
    //   index: this.index,
    // };
  },
  computed: {
    stars() {
      return Array(this.max)
        .fill()
        .map((_, index) => index + 1);
    },
  },
  methods: {
    selectRating(rating) {
      this.$emit("input", rating);
    },
    fixScore(data) {
      return parseFloat(data).toFixed(1);
    },
  },
};
</script>

<style>
.star i.fa-star {
  color: #ccc;
  cursor: pointer;
}

.star.filled i.fa-star {
  color: #ffc700;
}
.star.filled i.fa-star-half-alt {
  color: #ffc700;
}
</style>
